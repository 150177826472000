/*-----Jumbotron-----*/

.jumbotron {
	padding: 2rem 1rem;
	margin-bottom: 2rem;
	background-color: #eaeefa;
	border-radius: 3px;
}
@media (min-width: 576px) {
	.jumbotron {
		padding: 2rem;
	}
}
.jumbotron-fluid {
	padding-right: 0;
	padding-left: 0;
	border-radius: 0;
}
@media (max-width: 480px) {
	.jumbotron .display-3 {
			font-size: 2.5rem;
	}
}