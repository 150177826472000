.selectgroup {
	display: -ms-inline-flexbox;
	display: inline-flex;
}
.selectgroup-item {
	-ms-flex-positive: 1;
	flex-grow: 1;
	position: relative;
	+.selectgroup-item {
		margin-left: -1px;
	}
	&:not(:first-child) .selectgroup-button {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
	&:not(:last-child) .selectgroup-button {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
}
.selectgroup-button {
	display: block;
	border: $border;
	text-align: center;
	padding: 0.375rem 1rem;
	position: relative;
	cursor: pointer;
	border-radius: 3px;
	color: #9aa0ac;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	font-size: 0.9375rem;
	line-height: 1.5rem;
	min-width: 2.375rem;
}
.selectgroup-button-icon {
	padding-left: .5rem;
	padding-right: .5rem;
	font-size: 1rem;
}
.selectgroup-input {
	opacity: 0;
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	&:checked+.selectgroup-button {
		border-color: $primary;
		z-index: 1;
		color: $primary;
		background: #edf2fa;
	}
	&:focus+.selectgroup-button {
		border-color: $primary;
		z-index: 2;
		color: $primary;
		box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.25);
	}
}
.selectgroup-pills {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-align: start;
	align-items: flex-start;
	.selectgroup-item {
		margin-right: .5rem;
		-ms-flex-positive: 0;
		flex-grow: 0;
	}
	.selectgroup-button {
		border-radius: 50px !important;
	}
}
.custom-switch {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: default;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-align: center;
	align-items: center;
	margin: 0;
	padding-left: 0;
}
.custom-switch-input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}
.custom-switches-stacked {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	.custom-switch {
		margin-bottom: .5rem;
	}
}
.custom-switch-indicator {
	display: inline-block;
	height: 1.25rem;
	width: 2.25rem;
	background: #e9ecef;
	border-radius: 50px;
	position: relative;
	vertical-align: bottom;
	border: $border;
	transition: .3s border-color, .3s background-color;
	&:before {
		content: '';
		position: absolute;
		height: calc(1.25rem - 4px);
		width: calc(1.25rem - 4px);
		top: 1px;
		left: 1px;
		background: $white;
		border-radius: 50%;
		transition: .3s left;
		box-shadow: 0 1px 2px 0 $black-4;
	}
}
.custom-switch-input.danger:checked~.custom-switch-indicator {
	background: $danger;
	&:before {
		left: calc(1rem + 1px);
	}
}
.custom-switch-input.success:checked~.custom-switch-indicator {
	background: $success;
	&:before {
		left: calc(1rem + 1px);
	}
}
.custom-switch-input.orange:checked~.custom-switch-indicator {
	background: $orange;
	border:$orange;
	box-shadow:none;
	&:before {
		left: calc(1rem + 1px);
	}
}
.custom-switch-input:checked~.custom-switch-indicator {
	background: $primary;
	&:before {
		left: calc(1rem + 1px);
	}
}
.custom-switch-indicator-lg {
	display: inline-block;
	height: 1.5rem;
	width: 3rem;
	background: #f5f6fb;
	border-radius: 50px;
	position: relative;
	vertical-align: bottom;
	border: 1px solid #ebeaf1;
	transition: .3s border-color, .3s background-color;
	&:before {
		content: '';
		position: absolute;
		height: calc(1.5rem - 4px);
		width: calc(1.5rem - 4px);
		top: 1px;
		left: 1px;
		background: $white;
		border-radius: 50%;
		transition: .3s left;
		box-shadow: 0 1px 2px 0 $black-4;
	}
}
.custom-switch-input:checked~.custom-switch-indicator-lg:before {
	left: calc(1.46rem + 1px);
}
.custom-switch-indicator-xl {
	display: inline-block;
	height: 2rem;
	width: 4rem;
	background: #f5f6fb;
	border-radius: 50px;
	position: relative;
	vertical-align: bottom;
	border: 1px solid #ebeaf1;
	transition: .3s border-color, .3s background-color;
	&:before {
		content: '';
		position: absolute;
		height: calc(2rem - 4px);
		width: calc(2rem - 4px);
		top: 1px;
		left: 1px;
		background: $white;
		border-radius: 50%;
		transition: .3s left;
		box-shadow: 0 1px 2px 0 $black-4;
	}
}
.custom-square {
	border-radius: 0px !important;
	&:before {
		border-radius: 0px !important;
	}
}
.custom-radius {
	border-radius: 4px !important;
	&:before {
		border-radius: 4px !important;
	}
}
.custom-switch-input:focus~.custom-switch-indicator {
	box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.25);
	border-color: $primary;
}
.custom-switch-description {
	margin-left: .5rem;
	color: $muted;
	transition: .3s color;
}
.custom-switch-description-disable {
	opacity: .5;
}
.custom-switch-input:checked~.custom-switch-description {
	color: #313e6a;
}
.material-switch> {
	input[type="checkbox"] {
		display: none;
	}
	label {
		cursor: pointer;
		height: 0px;
		position: relative;
		width: 40px;
		&::before {
			background: rgb(243, 244, 247);
			box-shadow: inset 0px 0px 10px rgb(101, 116, 205);
			border-radius: 8px;
			content: '';
			height: 16px;
			margin-top: -8px;
			position: absolute;
			opacity: 0.3;
			transition: all 0.4s ease-in-out;
			width: 40px;
		}
		&::after {
			background: rgb(255, 255, 255);
			border-radius: 16px;
			box-shadow: 0px 0px 5px $black-3;
			content: '';
			height: 24px;
			left: -4px;
			margin-top: -8px;
			position: absolute;
			top: -4px;
			transition: all 0.3s ease-in-out;
			width: 24px;
		}
	}
	input[type="checkbox"]:checked+label {
		&::before {
			background: inherit;
			opacity: 0.5;
		}
		&::after {
			background: inherit;
			left: 20px;
		}
	}
}

.imagecheck {
	margin: 0;
	position: relative;
	cursor: pointer;
}
.imagecheck-figure {
	border: $border;
	border-radius: 3px;
	margin: 0;
	position: relative;
}
.imagecheck-input {
	position: absolute;
	z-index: -1;
	opacity: 0;
	&:focus~.imagecheck-figure {
		border-color: #467fcf;
		box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.25);
	}
	&:checked~.imagecheck-figure {
		border-color: rgba(0, 40, 100, 0.24);
	}
	&:focus~.imagecheck-figure .imagecheck-caption, &:checked~.imagecheck-figure .imagecheck-caption {
		color: #495057;
	}
	&:focus~.imagecheck-figure .imagecheck-image, &:checked~.imagecheck-figure .imagecheck-image {
		opacity: 1;
	}
}
.imagecheck-figure:before {
	content: '';
	position: absolute;
	top: .25rem;
	left: .25rem;
	display: block;
	width: 1rem;
	height: 1rem;
	pointer-events: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background: #467fcf url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") no-repeat center (center / 50%) 50%;
	color: $white;
	z-index: 1;
	border-radius: 3px;
	opacity: 0;
	transition: .3s opacity;
}
.imagecheck-input:checked~.imagecheck-figure:before {
	opacity: 1;
}
.imagecheck-image {
	max-width: 100%;
	opacity: .64;
	transition: .3s opacity;
	&:first-child {
		border-top-left-radius: 2px;
		border-top-right-radius: 2px;
	}
	&:last-child {
		border-bottom-left-radius: 2px;
		border-bottom-right-radius: 2px;
	}
}
.imagecheck:hover .imagecheck-image {
	opacity: 1;
}
.imagecheck-caption {
	text-align: center;
	padding: .25rem .25rem;
	color: #9aa0ac;
	font-size: 0.875rem;
	transition: .3s color;
}
.imagecheck:hover .imagecheck-caption {
	color: #495057;
}
.colorinput {
	margin: 0;
	position: relative;
	cursor: pointer;
}
.colorinput-color {
	display: inline-block;
	width: 1.75rem;
	height: 1.75rem;
	border-radius: 3px;
	border: $border;
	color: $white;
	box-shadow: 0 1px 2px 0 $black-05;
	&:before {
		content: '';
		opacity: 0;
		position: absolute;
		top: .25rem;
		left: .25rem;
		height: 1.25rem;
		width: 1.25rem;
		transition: .3s opacity;
		background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") no-repeat center (center / 50%) 50%;
	}
}
.colorinput-input {
	position: absolute;
	z-index: -1;
	opacity: 0;
	&:checked~.colorinput-color:before {
		opacity: 1;
	}
	&:focus~.colorinput-color {
		border-color: #467fcf;
		box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.25);
	}
}