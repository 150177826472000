/*------ Accordion -------*/

.accordion .card {
	&:not(:first-of-type) {
		&:not(:last-of-type) {
			border-bottom: 0;
			border-radius: 0;
		}
		.card-header:first-child {
			border-radius: 0;
		}
	}
	&:first-of-type {
		border-bottom: 0;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}
	&:last-of-type {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
}
.accordionjs .acc_section.acc_active>.acc_head {
	background: $primary !important;
}
#accordion1 .panel-title1 a:hover {
	color: $white !important;
}

.panel-title a.accordion-toggle {
	&:before {
		content: "\f068";
		padding: 0 10px 0 0;
		color: $white;
		font-family: FontAwesome;
		float: right;
	}
	&.collapsed:before {
		content: "\f067";
		padding: 0 10px 0 0;
		color: $white;
		font-family: FontAwesome;
		float: right;
	}
}
.demo-accordion p:last-child, .panel-group1 p:last-child {
	margin-bottom: 0;
}
#bs-collapse .panel-heading1 {
	a:after {
		content: "\f01a";
		font-size: 24px;
		position: absolute;
		font-family: 'FontAwesome';
		right: 5px;
		top: 10px;
		transform: scale(0);
		transition: all 0.5s;
	}
	&.active a:after {
		content: "\f01a";
		transform: scale(1);
		transition: all 0.5s;
	}
}
#accordion .panel-heading1 {
	a:before {
		content: "\f01a";
		font-size: 24px;
		position: absolute;
		font-family: 'FontAwesome';
		right: 5px;
		top: 10px;
		transform: rotate(180deg);
		transition: all 0.5s;
	}
	&.active a:before {
		transform: rotate(0deg);
		transition: all 0.5s;
	}
}
.acc-header a {
    display: block;
    padding: 15px;
	font-size: 14px;
    background-color: #ebeff8;
	border-bottom: 0;
	border: $border;
	border-radius: 5px;
	margin-top: 10px;
}
.acc-header a.collapsed{
	border-radius: 5px;
	border: $border;
}
.acc-body {
    padding: 15px;
    border: $border;
	border-top: 0;
	border-radius: 0px 0px 5px 5px;
}
.acc-card .collapse.show .acc-body{
	border-top: 0;
}
.acc-header.active a{
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.accordionjs .acc_section .acc_head {
	border-radius: 0!important;
}
.accordionjs .acc_section:first-of-type, .accordionjs .acc_section:first-of-type .acc_head {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
}
.accordionjs .acc_section:last-of-type, .accordionjs .acc_section:last-of-type .acc_content {
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}